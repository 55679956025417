import React from 'react';
import rehypeReact from 'rehype-react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Image from 'components/image';
import SelectedProject from 'components/selected-project';
import Credit from 'components/credit';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    lfsimage: Image,
    selectedproject: SelectedProject,
    credit: Credit,
  },
}).Compiler;

export default function Intro({ data }) {
  const introContent = data.introContent;
  return (
    <Layout>
      <div className="spacious">
        <div className="u-max-full-width">
          <img
            src="img/rotating-pads.svg"
            alt="Rotating Pads"
            className="rounded-corners"
          />
        </div>
        <div className="u-full-width">
          <h2>{introContent.frontmatter.title}</h2>
          {renderAst(introContent.htmlAst)}
        </div>
      </div>
    </Layout>
  );
}

Intro.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query Intro {
    introContent: markdownRemark(fields: { slug: { eq: "/pages/intro/" } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;
