import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { ProjectElement, ProjectHeader, Spacer } from './selected-project.css';

const SelectedProject = ({
  name,
  timeframe,
  description,
  id,
  alignment = 'left',
}) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible, hasBeenVisible }) => (
        <ProjectElement
          hasBeenVisible={hasBeenVisible}
          isVisible={isVisible}
          alignment={alignment}
        >
          <ProjectHeader alignment={alignment}>
            <span className="project-headline">{name}</span>
            <Spacer />
            <span className="project-timeframe">{timeframe}</span>
          </ProjectHeader>
          <p>{description}</p>
          <a className="button" href={'/projects/' + id}>
            Read more
          </a>
        </ProjectElement>
      )}
    </IO>
  );
};

SelectedProject.propTypes = {
  name: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'right']),
};

export default SelectedProject;
