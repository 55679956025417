import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ProjectElement = styled.div`
  transition: all 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1.0 : 0.0)};
  opacity: ${({ hasBeenVisible }) => (hasBeenVisible ? 1.0 : 0.0)};
  transform: translateY(${({ isVisible }) => (isVisible ? ' 0%' : ' 2%')});
  transform: translateY(
    ${({ hasBeenVisible }) => (hasBeenVisible ? ' 0%' : ' 2%')}
  );
  text-align: ${({ alignment }) => (alignment == 'left' ? 'left' : 'right')};
  margin-bottom: 8rem;
  ${MEDIA.TABLET`
  margin-bottom: 4rem;
  `};
`;

export const ProjectHeader = styled.div`
  display: flex;
  flex-direction: ${({ alignment }) =>
    alignment == 'left' ? 'row' : 'row-reverse'};
  align-items: baseline;
  flex-wrap: nowrap;
  margin-top: 3rem;
  margin-bottom: 2rem;
  ${MEDIA.DESKTOP`
    margin-top: 0;
    flex-direction: column;
    align-items: ${({ alignment }) =>
      alignment == 'left' ? 'flex-start' : 'flex-end'};
  `};
`;

export const Spacer = styled.div`
  width: 10%;
`;
